import React, { useEffect } from "react";
import { useSwitcherContext } from "./SwitcherContext";
import { Unit } from "../services/ems.service";


const HomescreenDataProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    
    const { setSwitcherDate,setPeriod,setUnit } = useSwitcherContext();
    useEffect(() => {
        setSwitcherDate(new Date())
        setUnit(Unit.KWH)
        setPeriod("day")
    }, []);
    return <>{children}</>;
};

export default HomescreenDataProvider;