import { useSwitcherContext } from "../contexts/SwitcherContext";
import "../assets/scss/components/BatteryGraphs.scss";
import { COLORS, TimeLabels, YYYY_MM_DD_HH_MM_SS, getFormatedDatetime } from "../utils/toolbox";
import { useNavigate } from "react-router-dom";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import React, { memo, useEffect } from "react";
import {
  MeasuredData,
  MeasuredDataDto,
  MonitoringItem,
  Unit,
  useLazyGetMonitoringActualAndForecastDataQuery,
  useLazyMonitoringQuery,
} from "../services/ems.service";
import { ReactComponent as BatteryUp } from "../assets/icons/ifpen-battery-up.svg";
import { ReactComponent as BatteryDown } from "../assets/icons/ifpen-battery-down-white.svg";
import { Switcher } from "./Switcher";
import LabeledBarChart from "./LabeledBarChart";
import moment from "moment";
import Panel from "./Panel";
import { Skeleton } from "@chakra-ui/react";
import EmsAlert from "./EmsAlert";
const BatteryGraphsComponent: React.FC = () => {
  //pBatGlobal
  const labelsBatt: TimeLabels[] = [];
  const pBatGlobalCharge: string[] = [];
  const pBatGlobalDeCharge: string[] = [];

  //socBatGlobal

  const labelsSocBatGlobal: TimeLabels[] = [];
  const socBatGlobal: string[] = [];

  const { unit, setUnit, period, setPeriod, switcherDate, setSwitcherDate } =
    useSwitcherContext();
  const navigate = useNavigate();
  const selectedTopoId = getSelectedTopo(store.getState()).id;
  useEffect(() => {
    if (selectedTopoId == "") {
      navigate("/redirect");
    }
  }, [selectedTopoId]);
  //const monitoringResponse =  useGetMonitoringDataQuery(switcherDate)
  const [
    monitoring,
    {
      data: monitoringWithForecast,
      isError: monitoringWithForecastIsError,
      isLoading: monitoringWithForecastIsLoading,
    },
  ] = useLazyMonitoringQuery();

  const fetchData = async () => {
    monitoring({
      date: moment(switcherDate).format(YYYY_MM_DD_HH_MM_SS),
      topologyId: selectedTopoId,
    });
  };
  useEffect(() => {
    setSwitcherDate(new Date());
    setPeriod("day");
    setUnit(Unit.KWH);
  }, [selectedTopoId]);
  useEffect(() => {
    if (!switcherDate) setSwitcherDate(new Date());
    fetchData();
  }, [switcherDate, selectedTopoId]);
  //Actual
  if (monitoringWithForecast) {
    //pBatGlobal
    const dataByPeriod: {
      [key: string]: MeasuredDataDto[] | string;
    } = (monitoringWithForecast[period] as MonitoringItem)[unit.toUpperCase()];
    const pBatGlobals = dataByPeriod["pBatGlobal"] as MeasuredDataDto[];
    pBatGlobals.forEach((item: MeasuredDataDto) => {
      getFormatedDatetime(item, period, labelsBatt);
      if (item.measure < 0) {
        pBatGlobalCharge.push("0");
      } else {
        pBatGlobalCharge.push(item.measure.toFixed(1));
      }
    });
    pBatGlobals.forEach((item: MeasuredDataDto) => {
      if (item.measure > 0) {
        pBatGlobalDeCharge.push("0");
      } else {
        pBatGlobalDeCharge.push(item.measure.toFixed(1));
      }
    });
    //socBatGlobal
    const socBatGlobals = dataByPeriod["socBatGlobal"] as MeasuredDataDto[];
    socBatGlobals.forEach((item: MeasuredDataDto) => {
      getFormatedDatetime(item, period, labelsSocBatGlobal);
      socBatGlobal.push((item.measure * 100).toFixed(1));
    });
  }

  const graphBatt = {
    labels: labelsBatt,
    datasets: [
      {
        key: 1,
        label: "Charge batterie",
        image: BatteryUp,
        darkImage: BatteryUp,
        data: pBatGlobalCharge,
        bgColor: COLORS.BATTERYUP,
        backgroundColor: "rgb(66, 216, 40)",
        borderWidth: 0,
        isForecast: false,
      },
      {
        key: 2,
        label: "Décharge batterie",
        image: BatteryDown,
        darkImage: BatteryDown,
        data: pBatGlobalDeCharge,
        bgColor: COLORS.BATTERYDOWN,
        backgroundColor: "rgb(16, 173, 119)",
        borderWidth: 0,
        isForecast: false,
      },
    ],
  };

  const graphSocBatGlobal = {
    labels: labelsSocBatGlobal,
    datasets: [
      {
        key: 3,
        label: "Etat de charge de la batterie",
        image: BatteryUp,
        darkImage: BatteryUp,
        data: socBatGlobal,
        bgColor: COLORS.EMS_BLUE_SEMI_LIGHT,
        backgroundColor: COLORS.EMS_BLUE_SEMI_LIGHT,
        borderWidth: 0,
        isForecast: false,
      },
    ],
  };

  return (
    <>
      {monitoringWithForecastIsError ? (
        <EmsAlert
          status="error"
          title={"Erreur lors de la récupération des données"}
          description={"Error"}
        ></EmsAlert>
      ) : (
        <>
          <Skeleton
            isLoaded={!monitoringWithForecastIsLoading}
            className="h-full max-h-full min-h-full"
          >
            <Panel
              className="h-full max-h-full min-h-full"
              title={
                <div className="text-center text-[#0488F9] md:mt-2 text-xl md:text-3xl font-extrabold">
                  {" "}
                  Potentiel de charge{" "}
                </div>
              }
            >
              <Switcher
                units={[Unit.KWH]}
                withDates={true}
                withPeriods={false}
                className="h-full"
              >
                <LabeledBarChart
                  data={graphSocBatGlobal}
                  getDataForLabel={() => {}}
                  displayCols
                />
              </Switcher>
            </Panel>
          </Skeleton>
          <Skeleton
            isLoaded={!monitoringWithForecastIsLoading}
            className="h-full max-h-full min-h-full"
          >
            <Panel
              className="h-full max-h-full min-h-full"
              title={
                <div className="text-center text-[#0488F9] md:mt-2 text-xl md:text-3xl font-extrabold">
                  {" "}
                  Activites de la batterie{" "}
                </div>
              }
            >
              <Switcher
                units={[Unit.KWH]}
                withDates={true}
                withPeriods={false}
                className="h-full"
              >
                <LabeledBarChart
                  data={graphBatt}
                  getDataForLabel={() => {}}
                  displayCols
                />
              </Switcher>
            </Panel>
          </Skeleton>
        </>
      )}
    </>
  );
};
export const BatteryGraphs: React.FC = memo(BatteryGraphsComponent);
