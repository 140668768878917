import React from "react";
import Battery from "../components/Battery";
import { Doughnut } from "react-chartjs-2";
import { centerTextPlugin } from "../utils/chartjsLabelPlugin";
import { ReactComponent as Bolt } from "../assets/img/Bolt.svg";
import WidgetCard from "../components/WidgetCard";
import { ReactComponent as MaisonIfpen } from "../assets/img/MaisonIfpen.svg";
import { ReactComponent as CompteurIfpen } from "../assets/img/CompteurIfpen.svg";
import { ReactComponent as PoteauIfpen } from "../assets/img/PoteauIfpen.svg";
import { ReactComponent as SolarFlow } from "../assets/img/SolarFlow.svg";
import { ReactComponent as ConsumptionFlow } from "../assets/img/ConsumptionFlow.svg";
import { ReactComponent as ExternalConsumptionFlow } from "../assets/img/ExternalConsumptionFlow.svg";
import { ReactComponent as SupplierFlow } from "../assets/img/SupplierFlow.svg";
import { ReactComponent as BatteryChargeInputFlow } from "../assets/img/BatteryChargeInputFlow.svg";
import { ReactComponent as BatteryChargeInputFlowNone } from "../assets/img/BatteryChargeInputFlowNone.svg";
import { ReactComponent as SupplierFlowNone } from "../assets/img/SupplierFlowNone.svg";
import { ReactComponent as BatteryChargeOutputFlow } from "../assets/img/BatteryChargeOutputFlow.svg";
import "../assets/scss/components/MainHouse.scss";
import { getMinutesUntilUpdate } from "../utils/toolbox";
import { Link } from "react-router-dom";
import { HorizontalScroll } from "./HorizontalScroll";
import { Context } from "chartjs-plugin-datalabels";
import { Point } from "framer-motion";
import { BubbleDataPoint } from "chart.js";
import { Box, Text } from "@chakra-ui/react";
interface WidgetMainHouseProps {
  supplierFlowkW?: number;
  solarFlowkW?: number;
  consumptionFlowkW?: number;
  batteryFlowkW?: number;
  nextChargeDate?: string;
  batteryCharge100?: number;
  pProdGlobal?: number;
  pProdConsByConsumers?: number;
  pProdConsByBat?: number;
  pProdSentToGrid?: number;
  pConsoGlobal?: number;
  pConsoFromProd?: number;
  pConsoFromGrid?: number;
  pConsoFromBat?: number;
  timeRemaining?: number;
}

/**
 * Composant House représentant les différents flux de production/consommation du réseau.
 *
 * @param supplierFlowkW number
 * @param solarFlowkW number
 * @param consumptionFlowkW number
 * @param batteryFlowkW number
 * @returns House
 */
const MainHouse: React.FC<WidgetMainHouseProps> = ({
  supplierFlowkW,
  solarFlowkW,
  consumptionFlowkW,
  batteryFlowkW,
  nextChargeDate = "",
  batteryCharge100 = 0,
  pProdGlobal,
  pProdConsByConsumers,
  pProdConsByBat,
  pProdSentToGrid,
  pConsoGlobal,
  pConsoFromProd,
  pConsoFromGrid,
  pConsoFromBat,
  timeRemaining,
}) => {
  const chartConsumptionOption = {
    cutout: "40%",
    hoverOffset: 4,
    borderWidth: 0,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "white",
        // Permet d'éviter que les labels se chevauchent si les segments sont trop petits.
        display: "auto",
        formatter: function (value: number, context: Context) {
          let sum = 0;
          const dataArr = context.chart.data.datasets[0].data;
          dataArr.map((data: number | [number, number] | Point | BubbleDataPoint | null) => {
            return (sum += Number(data));
          });
          return value / sum > 0.05 ? value : ""; // Ajustez 0.05 à la limite que vous voulez
        },
      },
      centerTextPlugin: {
        text: pConsoGlobal?.toFixed(2),
      },
    },
  };

  const chartProductionOption = {
    cutout: "40%",
    hoverOffset: 4,
    borderWidth: 0,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "white",
        // Permet d'éviter que les labels se chevauchent si les segments sont trop petits.
        display: "auto",
        formatter: function (value: number, context: Context) {
          let sum = 0;
          const dataArr = context.chart.data.datasets[0].data;
          dataArr.map((data: number | [number, number] | Point | BubbleDataPoint | null) => {
            return (sum += Number(data));
          });
          return value / sum > 0.1 ? value : ""; // Ajustez 0.05 à la limite que vous voulez
        },
      },
      centerTextPlugin: {
        text: pProdGlobal?.toFixed(2),
        backgroundColor: "#ebdc0e",
        shadowColor: "#b8ac0dda",
        textColor: "#ffffff",
      },
    },
  };

  // Données pour le graphique de la répartition instantanée.
  const dailyDistribution = {
    labels: ["Charge batterie", "Surplus réinjecté", "Directement consommée"],
    datasets: [
      {
        data: [
          pProdConsByBat?.toFixed(2),
          pProdSentToGrid?.toFixed(2),
          pProdConsByConsumers?.toFixed(2),
        ],
        backgroundColor: ["#43D820", "#E02FEF", "#fc8102"],
      },
    ],
  };

  // Données pour le graphique de la production directement consommée.
  const provenance = {
    labels: ["Décharge batterie", "Réseau", "Production solaire"],
    datasets: [
      {
        data: [
          pConsoFromBat?.toFixed(2),
          pConsoFromGrid?.toFixed(2),
          pConsoFromProd?.toFixed(2),
        ],
        backgroundColor: ["#10AD77", "#E00000", "#fc8102"],
      },
    ],
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const plugins: any = {
    tooltip: {
      enabled: true,
    },
    datalabels: {
      enabled: true,
    },
  };
  return (
    <div className="main-house">
      <HorizontalScroll
      >
        {
          <>
            {batteryCharge100 > 0 && (
              <Link to="/my-battery">
                <WidgetCard
                  title="Batterie"
                  subTitle=""
                  text=""
                  topElement={
                    <Battery
                      batteryValue={batteryCharge100}
                      className=" mx-auto mb-5 max-h-24"
                    />
                  }
                  bottomElement={
                    <Text
                      color="#FFFFFF"
                      background="#0FBAB8"
                      className="rounded-full w-[70px] max-w-18 m-auto p-1 text-center font-extrabold leading-tight mt-2"
                    >
                      <Bolt className="inline mb-1 mr-2" />
                      {Math.round(batteryCharge100)}%
                    </Text>
                  }
                />
              </Link>
            )}

            {batteryCharge100 <= 0 && (
              <Link to="/my-battery">
                <WidgetCard
                  title="Batterie"
                  subTitle="Prochaine charge"
                  text={`Dans ${getMinutesUntilUpdate(nextChargeDate)}'`}
                  topElement={
                    <Battery
                      batteryValue={batteryCharge100}
                      className=" mx-auto mb-5 max-h-24"
                    />
                  }
                  bottomElement={
                    <Text
                      color="#FFFFFF"
                      background="#0FBAB8"
                      className="rounded-full w-[70px] max-w-18 m-auto p-1 text-center font-extrabold leading-tight mt-2"
                    >
                      <Bolt className="inline mb-1 mr-2" />
                      {batteryCharge100}%
                    </Text>
                  }
                />
              </Link>
            )}

            <WidgetCard
              title="Production solaire"
              subTitle="Répartition instantanée"
              text={`Mise à jour dans ${timeRemaining}s`}
              topElement={
                <Doughnut
                  options={chartProductionOption}
                  data={dailyDistribution}
                  plugins={[plugins, centerTextPlugin]}
                  className="mb-5 max-h-24"
                />
              }
              bottomElement={
                <Box>
                  <Text color="#003265" className="text-xxs">
                    <span className="rounded-full w-[10px] h-[10px] inline-block mr-1 align-middle my-1 bg-[#E02FEF]"></span>
                    Surplus réinjecté
                  </Text>

                  <Text color="#003265" className="text-xxs">
                    <span className="rounded-full w-[10px] h-[10px] inline-block mr-1 align-middle my-1 bg-[#43D820]"></span>
                    Charge batterie
                  </Text>

                  <Text color="#003265" className="text-xxs">
                    <span className="rounded-full w-[10px] h-[10px] inline-block mr-1 align-middle my-1 bg-[#fc8102]"></span>
                    Directement consommé
                  </Text>
                </Box>
              }
            />
            <WidgetCard
              title="Consommation"
              subTitle="Provenance instantanée"
              text={`Mise à jour dans ${timeRemaining}s`}
              topElement={
                <Doughnut
                  options={chartConsumptionOption}
                  data={provenance}
                  plugins={[plugins, centerTextPlugin]}
                  className="mb-5 max-h-24"
                />
              }
              bottomElement={
                <Box>
                  <Text color="#003265" className="text-xxs">
                    <span className="rounded-full w-[10px] h-[10px] inline-block mr-1 align-middle my-1 bg-[#E00000]"></span>
                    Réseau
                  </Text>

                  <Text color="#003265" className="text-xxs">
                    <span className="rounded-full w-[10px] h-[10px] inline-block mr-1 align-middle my-1 bg-[#10AD77]"></span>
                    Décharge batterie
                  </Text>

                  <Text color="#003265" className="text-xxs">
                    <span className="rounded-full w-[10px] h-[10px] inline-block mr-1 align-middle my-1 bg-[#fc8102]"></span>
                    Production solaire
                  </Text>
                </Box>
              }
            />
          </>
        }
      </HorizontalScroll>

      <div className="main-house__container">
        {/* Placement des différents éléments dans une frame, l'ordre des éléments est important car il impacte le z-index */}
        <MaisonIfpen className="main-house__container__maison-ifpen" />
        <SolarFlow className="main-house__container__solar-flow" />

        {/* Si la batterie se décharge : */}
        {batteryFlowkW !== undefined && batteryFlowkW < 0 && (
          <div>
            <BatteryChargeOutputFlow className="main-house__container__battery-charge-output-flow" />
            <div className="main-house__container__battery-output-flow-label">
              {Math.abs(parseFloat(batteryFlowkW.toFixed(2)))} kW
            </div>
          </div>
        )}

        {/* Si la batterie se charge : */}
        {batteryFlowkW !== undefined && batteryFlowkW > 0 && (
          <div>
            <BatteryChargeInputFlow className="main-house__container__battery-charge-input-flow" />
            <div className="main-house__container__battery-input-flow-label">
              {batteryFlowkW.toFixed(2)} kW
            </div>
          </div>
        )}

        {/* Si il n'y a pas de flux de batterie */}
        {batteryFlowkW !== undefined && batteryFlowkW === 0 && (
          <div>
            <BatteryChargeInputFlowNone className="main-house__container__battery-charge-input-flow" />
            <div className="main-house__container__battery-input-flow-label-none">
              {batteryFlowkW.toFixed(2)} kW
            </div>
          </div>
        )}
        <Battery
          className="main-house__container__batterie-ifpen"
          batteryValue={batteryCharge100}
        />
        <ConsumptionFlow className="main-house__container__consumption-flow" />
        <PoteauIfpen className="main-house__container__poteau-ifpen" />
        <CompteurIfpen className="main-house__container__compteur-ifpen" />

        {/* Si l'installation réinjecte le surplus */}
        {supplierFlowkW !== undefined && supplierFlowkW > 0 && (
          <div>
            <SupplierFlow className="main-house__container__supplier-flow" />
            <div className="main-house__container__supplier-flow-label">
              {supplierFlowkW.toFixed(2)} kW
            </div>
          </div>
        )}

        {/* Si l'installation consomme sur le réseau */}
        {supplierFlowkW !== undefined && supplierFlowkW < 0 && (
          <div>
            <ExternalConsumptionFlow className="main-house__container__external-consumption-flow" />
            <div className="main-house__container__external-consumption-flow-label">
              {Math.abs(parseFloat(supplierFlowkW.toFixed(2)))} kW
            </div>
          </div>
        )}

        {/* Si l'installation ne consomme rien et ne produit rien */}
        {supplierFlowkW !== undefined && supplierFlowkW === 0 && (
          <div>
            <SupplierFlowNone className="main-house__container__supplier-flow" />
            <div className="main-house__container__supplier-flow-label-none">
              {supplierFlowkW.toFixed(2)} kW
            </div>
          </div>
        )}
        <div className="main-house__container__solar-flow-label">
          {solarFlowkW?.toFixed(2)} kW
        </div>

        <div className="main-house__container__consumption-flow-label">
          {consumptionFlowkW?.toFixed(2)} kW
        </div>
      </div>
      {/*#{getMinutesUntilUpdate(nextChargeDate) == 0 ? "Chargement en cours": ""} 
            `Nouveau chargement dans ${getMinutesUntilUpdate(nextChargeDate)}'`
            */}
    </div>
  );
};

export default MainHouse;
