import React, { ReactNode} from "react";
import "../assets/scss/components/CircularDiv.scss";
import { Icon} from "@chakra-ui/react";
interface CircularDivProps {
  color: string;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  children?: ReactNode;
  withOpacity?: boolean;
}
export const CircularDiv: React.FC<CircularDivProps> = ({
  color,
  icon,
  withOpacity = true,
}) => {
  return withOpacity ? (
    <div className={`circular-div`}>
      <div
        className={`half-circle-left`}
        style={{ backgroundColor: color }}
      ></div>
      <div
        className={`half-circle-right`}
        style={{ backgroundColor: color, opacity: "50%" }}
      ></div>
      <Icon
        as={icon}
        className={`circle-image `}
        boxSize={"sm"}
        fill={"white"}
      />
    </div>
  ) : (
    <div className={`circular-div`} style={{ backgroundColor: color }}>
      <Icon
        as={icon}
        className={`circle-image`}
        boxSize={"sm"}
        fill={"white"}
      />
    </div>
  );
};
