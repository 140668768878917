import { ResponsiveLayout } from "../layouts/ResponsiveLayout";
import React from "react";
import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import "../assets/scss/pages/PageIndicator.scss";
import { MonitoringIndicators } from "../components/MonitoringIndicators";
import { AutonomieIndicator } from "../components/AutonomieIndicator";
export const PageIndicator: React.FC = () => {
  const ResponsiveContent = ({ isMd }: { isMd: boolean }) => (
    <>
      <ResponsiveLayout
        className="hidden md:flex"
        rightSide={<MonitoringIndicators />}
        leftSide={
          <Box
            flex="1"
            className="py-4 w-full md:max-w-fit mx-auto list"
            overflow="auto"
          >
            <AutonomieIndicator />
          </Box>
        }
      />
      <ResponsiveLayout className="flex md:hidden">
        <Flex
          flex="2"
          py={2}
          px={2}
          flexDirection="column"
          className="overflow-y-auto"
          gap={2}
        >
          <Box
            flex="1"
            className="py-4 w-full md:max-w-fit mx-auto"
            overflow="auto"
          >
            <AutonomieIndicator />
          </Box>
          <MonitoringIndicators />
        </Flex>
      </ResponsiveLayout>
    </>
  );

  const isAboveMd = useBreakpointValue({ base: false, md: true });
  return <ResponsiveContent isMd={isAboveMd || true} />;
};
