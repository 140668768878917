import { Routes, Route, Navigate, useLocation } from "react-router-dom";  
import { SwitcherProvider } from "../contexts/SwitcherContext";
import HomescreenDataProvider from "../contexts/HomescreenDataProvider";
import HomeBalanceDataProvider from "../contexts/HomeBalanceDataProvider"; 
import { MonComptePage } from "../pages/MonComptePage";
import { PageAdmin } from "../pages/PageAdmin";
import { FluxTopologyPage } from "../pages/FluxTopologyPage";
import LoginLayout from "../layouts/LoginLayout";
import LoginPage from "../pages/LoginPage";
import { UnauthorizedPage } from "../pages/UnauthorizedPage"; 
import { WeatherPage } from "../pages/WeatherPage";
import { RedirectPage } from "../pages/RedirectPage";
import { PrivateRoute } from "./PrivateRoute";
import { adminRoles, installerRoles, userRoles } from "../utils/toolbox";
import { HomePage } from "../pages/HomePage";
import { PageBattery } from "../pages/PageBattery";
import { PageEcoCoaching } from "../pages/PageEcoCoaching";
import { PageIndicator } from "../pages/PageIndicator";
import React from "react";
import { ResponsiveLayout } from "../layouts/ResponsiveLayout";

const AppRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <Routes>
      {/* Route "Connexion" */}
      <Route
        path="/login"
        element={
          <LoginLayout className="min-h-screen">
            <LoginPage />
          </LoginLayout>
        }
      />
      <Route
        path="/unauthorized"
        element={
          <ResponsiveLayout>
            <UnauthorizedPage />
          </ResponsiveLayout>
        }
      />
      <Route element={<PrivateRoute allowedRoles={userRoles()} />}>
        <Route
          path="/redirect"
          element={
            <ResponsiveLayout>
              <RedirectPage />
            </ResponsiveLayout>
          }
        />
        {/* Route "Mon installation" */}
        <Route
          path="/my-installation"
          element={
            <SwitcherProvider>
              <HomescreenDataProvider>
                <HomeBalanceDataProvider>
                  <HomePage />
                </HomeBalanceDataProvider>
              </HomescreenDataProvider>
            </SwitcherProvider>
          }
        />
        {/** Meteo */}
        <Route
          path="/weather"
          element={
            <SwitcherProvider>
              <WeatherPage />
            </SwitcherProvider>
          }
        />

        {/* 
        
        <Route
          path="/my-invoice"
          element={
            <ResponsiveLayout>
              <MyInvoicePage />
            </ResponsiveLayout>
          }
        />
            <Route
          path="/lexique"
          element={
            <ResponsiveLayout>
              <LexiconPage />
            </ResponsiveLayout>
          }
        />
           <Route
          path="/lexicon"
          element={
            <ResponsiveLayout>
              <LexiconPage />
            </ResponsiveLayout>
          }
        />
        */}

        {/* Route "Ma production solaire" */}

        {/* 
  
    <Route
          path="/my-solar-production"
          element={
            <SwitcherProvider>
                <MySolarProductionPage />
            </SwitcherProvider>
          }
        />
  */}
        {/* Route "Ma consommation globale d'énergie" */}

        {/* 
           <Route
          path="/my-solar-consumption"
          element={
            <SwitcherProvider>
                <MySolarConsumptionPage />
            </SwitcherProvider>
          }
        />
          */}

        <Route
          path="/indicators"
          element={
            <SwitcherProvider>
              <PageIndicator />
            </SwitcherProvider>
          }
        />
        {/* 
          <Route
          path="/installation-details"
          element={
            <ResponsiveLayout>
              <InstallationDetailsPage />
            </ResponsiveLayout>
          }
        />
          */}

        <Route
          path="/mon-compte"
          element={
            <ResponsiveLayout>
              <MonComptePage />
            </ResponsiveLayout>
          }
        />
        <Route
          path="/my-battery"
          element={
            <SwitcherProvider>
              <PageBattery />
            </SwitcherProvider>
          }
        />
        <Route
          path="/my-coaching"
          element={
            <SwitcherProvider>
              <PageEcoCoaching />
            </SwitcherProvider>
          }
        />
      </Route>
      <Route element={<PrivateRoute allowedRoles={adminRoles()} />}>
        <Route
          path="/administration"
          element={
            <ResponsiveLayout>
              <PageAdmin />
            </ResponsiveLayout>
          }
        />
      </Route>
      <Route element={<PrivateRoute allowedRoles={installerRoles()} />}>
        <Route
          path="/flux-topology"
          element={
            <ResponsiveLayout>
              <FluxTopologyPage />
            </ResponsiveLayout>
          }
        />
      </Route>
      <Route
        path="*"
        element={<Navigate to="/redirect" replace state={{ from: location }} />}
      />
    </Routes>
  );
};

export default AppRoutes;
