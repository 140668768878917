import { COLORS, intervalId, symbolMap } from "../utils/toolbox";
import { HomePageIndicator } from "./HomePageIndicator";
import InvoicePanel from "./InvoicePanel";
import Panel from "./Panel";
import { Switcher } from "./Switcher";
import React, { useCallback, useEffect, useState } from "react";
import { displayUnit, useSwitcherContext } from "../contexts/SwitcherContext";
import { Unit, useLazyGetHomeBalanceDataQuery } from "../services/ems.service";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import { useNavigate } from "react-router-dom";
import { Box, Skeleton } from "@chakra-ui/react";

export const HomePageGraphs: React.FC = () => {
  const { unit, setUnit, period, setPeriod, switcherDate, setSwitcherDate } =
    useSwitcherContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>();
  const [getHomeBalanceData] = useLazyGetHomeBalanceDataQuery();
  const topologyId = getSelectedTopo(store.getState()).id;
  const navigate = useNavigate();
  useEffect(() => {
    if (topologyId == "") {
      navigate("/redirect");
    }
  }, [topologyId]);

  useEffect(() => {
    setSwitcherDate(new Date());
    setUnit(Unit.KWH);
    setPeriod("day");
  }, []);

  const getHomeBalanceDataByDateAndTopo = useCallback(() => {
    getHomeBalanceData({ date: switcherDate, topologyId })
      .unwrap()
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [switcherDate, topologyId]);
  useEffect(() => {
    if (!switcherDate) setSwitcherDate(new Date());
    getHomeBalanceDataByDateAndTopo();
  }, [switcherDate, topologyId]);
  //TODO : enlever le switcher date : car pas de valeurs instantannéé pour la maison
  useEffect(() => {
    if (!switcherDate) setSwitcherDate(new Date());
    getHomeBalanceDataByDateAndTopo();
    const timer1 = setInterval(() => {
      getHomeBalanceDataByDateAndTopo();
    }, intervalId * 60 * 1000);
    return () => {
      window.clearInterval(timer1);
    };
  }, [switcherDate, topologyId]);

  const formatEnergyUnit = (total: number, unit: string) => {
    if (unit == "kWh") {
      if (total / 1000 >= 1) return (total / 1000).toFixed(1) + " MWh";
      else return total.toFixed(1) + " kWh";
    } else {
      return total.toFixed(1) + displayUnit(unit, symbolMap);
    }
  };
  const getGraphProdTotal = (unit: string) => {
    if (!data) return "";
    const total =
      Math.abs(parseFloat(data[period]["prod" + unit]["battery"])) +
      Math.abs(parseFloat(data[period]["prod" + unit]["solar"])) +
      Math.abs(parseFloat(data[period]["prod" + unit]["provider"]));
    return formatEnergyUnit(total, unit);
  };

  const getGraphConsoTotal = (unit: string) => {
    if (!data) return "";
    const total: number =
      Math.abs(parseFloat(data[period]["conso" + unit]["battery"])) +
      Math.abs(parseFloat(data[period]["conso" + unit]["solar"])) +
      Math.abs(parseFloat(data[period]["conso" + unit]["provider"]));

    return formatEnergyUnit(total, unit);
  };
  const getGraphData = (data: any, type: string) => {
    if (!data) return [];
    return [
      data[period][type + unit]["battery"].toFixed(1),

      data[period][type + unit]["provider"].toFixed(1),
      data[period][type + unit]["solar"].toFixed(1),
    ];
  };
  const getEnergyDistribution = (data: any, type: string) => {
    if (!data) return [];
    if (type == "prod") {
      return [
        {
          label: "Production solaire directement consommée",
          color: COLORS.PROD,
          value: formatEnergyUnit(data[period]["prod" + unit]["solar"], unit),
        },
        {
          label: "Surplus réinjecté dans le réseau",
          color: COLORS.SURPLUS,
          value: formatEnergyUnit(
            data[period]["prod" + unit]["provider"],
            unit
          ),
        },
        {
          label: "Charge batterie",
          color: COLORS.BATTERYUP,
          value: formatEnergyUnit(data[period]["prod" + unit]["battery"], unit),
        },
      ];
    } else {
      return [
        {
          label: "Production solaire directement consommée",
          color: COLORS.PROD,
          value: formatEnergyUnit(
            Math.abs(parseFloat(data[period]["conso" + unit]["solar"])),
            unit
          ),
        },
        {
          label: "Consommation issue du réseau",
          color: COLORS.CONSO,
          value: formatEnergyUnit(
            Math.abs(parseFloat(data[period]["conso" + unit]["provider"])),
            unit
          ),
        },
        {
          label: "Décharge batterie",
          color: COLORS.BATTERYDOWN,
          value: formatEnergyUnit(
            Math.abs(parseFloat(data[period]["conso" + unit]["battery"])),
            unit
          ),
        },
      ];
    }
  };
  return (
    <Box className="w-full flex flex-col items-center gap-4">
      <Skeleton isLoaded={data} className="w-full md:max-w-fit">
        <Panel className="w-full md:max-w-fit">
          <Switcher withUnits={false}>
            <InvoicePanel
              homeBalanceData={data ? data[period]["prodEuro"] : ""}
            />
          </Switcher>
        </Panel>
      </Skeleton>
      <Skeleton isLoaded={data} className="w-full md:max-w-fit">
        <HomePageIndicator
          globalValue={unit === Unit.PCT ? "" : getGraphProdTotal(unit)}
          title="Ma production globale d'énergie"
          link="/indicators"
          graphData={{
            backgroundColor: ["#42d828", "#e02fef", "#fc8102"],
            labels: [
              "Charge Batterie",
              "Surplus réinjecté",
              "Directement consommée",
            ],
            datas: getGraphData(data, "prod"),
          }}
          unit={unit}
          energyDistribution={getEnergyDistribution(data, "prod")}
        />
      </Skeleton>
      <Skeleton isLoaded={data} className="w-full md:max-w-fit">
        <HomePageIndicator
          globalValue={unit === Unit.PCT ? "" : getGraphConsoTotal(unit)}
          title="Ma consommation globale d'énergie"
          link="/indicators"
          graphData={{
            backgroundColor: ["#fc8102", "#e00001", "#039b0c"],
            labels: ["Production solaire", "Réseau", "Décharge batterie"],
            datas: getGraphData(data, "conso"),
          }}
          unit={unit}
          energyDistribution={getEnergyDistribution(data, "conso")}
        />
      </Skeleton>
    </Box>
  );
};
