import { ReactNode } from "react";
import Panel from "./Panel";
import { Switcher } from "./Switcher";
import { Skeleton } from "@chakra-ui/react";
import EmsAlert from "./EmsAlert";
import { getLoadingDataErrorByStatus } from "../utils/toolbox";
import { useGetAutonomieIndicatorDataQuery } from "../services/ems.service";
import { useSwitcherContext } from "../contexts/SwitcherContext";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import AutonomiePanel from "./AutonomiePanel";

export const AutonomieIndicator: React.FC = () => {
  const { unit, setUnit, period, setPeriod, switcherDate, setSwitcherDate } =
    useSwitcherContext();
  const selectedTopoId = getSelectedTopo(store.getState()).id;
  const {
    data: autonomieData,
    isError: autonomieIsError,
    error: autonomieError,
    isLoading: autonomieIsLoading,
  } = useGetAutonomieIndicatorDataQuery({
    switcherDate,
    topologyId: selectedTopoId,
  });
  const SkeletonContent = ({
    isLoading,
    children,
  }: {
    isLoading: boolean;
    children: ReactNode;
  }) => (
    <Skeleton isLoaded={!isLoading} className="h-full max-h-full min-h-full">
      {children}
    </Skeleton>
  );
  const ErrorAlert = ({
    title,
    description,
  }: {
    title: string;
    description: string;
  }) => (
    <EmsAlert status="error" title={title} description={description || ""} />
  );
  return (
    <Panel
      title="Mon autonomie"
      withArrow={false}
      className="w-full md:max-w-fit"
    >
      <Switcher>
        {autonomieIsError ? (
          <ErrorAlert
            title={`${
              "status" in autonomieError
                ? getLoadingDataErrorByStatus(autonomieError.status).titre
                : getLoadingDataErrorByStatus(undefined).titre
            } Lors du chargement des données de l'autonomie`}
            description={
              "status" in autonomieError
                ? getLoadingDataErrorByStatus(autonomieError.status).message
                : getLoadingDataErrorByStatus(undefined).message
            }
          />
        ) : (
          <SkeletonContent isLoading={autonomieIsLoading}>
            <AutonomiePanel
              date={switcherDate}
              data={autonomieData}
              period={period}
              unit={unit}
            />
          </SkeletonContent>
        )}
      </Switcher>
    </Panel>
  );
};
