import { Flex, Skeleton } from "@chakra-ui/react";
import LabeledBarChart from "./LabeledBarChart";
import { Switcher } from "./Switcher";
import Panel from "./Panel";
import EmsAlert from "./EmsAlert";
import {
  COLORS,
  createDataset,
  getFormatedDatetime,
  processMeasurePush,
  TimeLabels,
  YYYY_MM_DD_HH_MM_SS,
} from "../utils/toolbox";

//legendes images
import { ReactComponent as Sun } from "../assets/icons/ifpen-sun.svg";
import { ReactComponent as Pylon } from "../assets/icons/ifpen-pylon.svg";
import { ReactComponent as BatteryUp } from "../assets/icons/ifpen-battery-up.svg";
import { ReactComponent as BatteryDown } from "../assets/icons/ifpen-battery-down.svg";
import { useSwitcherContext } from "../contexts/SwitcherContext";
import { useNavigate } from "react-router-dom";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import React, { ReactNode, useEffect, useState } from "react";
import {
  DataCategory,
  Measure,
  Unit,
  useMonitoringQuery,
} from "../services/ems.service";
import moment from "moment";

export const MonitoringIndicators: React.FC = () => {
  const labelsProd: TimeLabels[] = [];
  const labelsConsum: TimeLabels[] = [];
  //prod
  const pProdGlobal: Measure[] = [];
  const pProdConsByBat: Measure[] = [];
  const pProdSentToGrid: Measure[] = [];
  const pProdConsByConsumers: Measure[] = [];
  //conso
  const pConsoGlobal: Measure[] = [];
  const pConsoFromProd: Measure[] = [];
  const pConsoFromBat: Measure[] = [];
  const pConsoFromGrid: Measure[] = [];

  //prod
  const pProdGlobalForecast: Measure[] = [];
  const pProdConsByBatForecast: Measure[] = [];
  const pProdSentToGridForecast: Measure[] = [];
  const pProdConsByConsumersForecast: Measure[] = [];
  //conso
  const pConsoGlobalForecast: Measure[] = [];
  const pConsoFromProdForecast: Measure[] = [];
  const pConsoFromBatForecast: Measure[] = [];
  const pConsoFromGridForecast: Measure[] = [];

  const [pConsoGlobalValue, setPConsoGlobalValue] = useState<number>(0);
  const [pProdGlobalValue, setPProdGlobalValue] = useState<number>(0);

  const [dataConso, setDataConso] = useState<any>();
  const [dataProd, setDataProd] = useState<any>();

  const { unit, setUnit, period, setPeriod, switcherDate, setSwitcherDate } =
    useSwitcherContext();

  const navigate = useNavigate();
  const selectedTopoId = getSelectedTopo(store.getState()).id;

  //const monitoringResponse =  useGetMonitoringDataQuery(switcherDate)

  useEffect(() => {
    if (selectedTopoId == "") {
      navigate("/redirect");
    }
  }, [selectedTopoId]);

  useEffect(() => {
    setSwitcherDate(new Date());
    setUnit(Unit.KWH);
    setPeriod("day");
  }, [selectedTopoId]);

  const {
    data : historyData,
    isError: historyDataIsError,
    isFetching: historyDataIsFetching,
  } = useMonitoringQuery(
    {
      dataCategory:DataCategory.HISTORY,
      date: moment(switcherDate).format(YYYY_MM_DD_HH_MM_SS),
      topologyId: selectedTopoId,
    },
    { refetchOnMountOrArgChange: true }
  );
  const {
    data:forecastData,
    isError: forecastDataIsError,
    isFetching: forecastDataIsFetching,
  } = useMonitoringQuery(
    {
      dataCategory:DataCategory.FORECAST,
      date: moment(switcherDate).format(YYYY_MM_DD_HH_MM_SS),
      topologyId: selectedTopoId,
    },
    { refetchOnMountOrArgChange: true }
  );
  useEffect(() => {

    if(historyDataIsFetching || forecastDataIsFetching || historyDataIsError || forecastDataIsError) return;
      //history
      //prod
      processMeasurePush(
        historyData,
        "pProdGlobal",
        period,
        Unit.KWH,
        pProdGlobal
      );
      if (pProdGlobal.length > 0) {
        setPProdGlobalValue(pProdGlobal.map(elt=>elt.measure).reduce((a, b) => a + b));
      }

      processMeasurePush(
        historyData,
        "pProdConsByConsumers",
        period,
        unit,
        pProdConsByConsumers,
        (item) => getFormatedDatetime(item, period, labelsProd)
      );
      processMeasurePush(
        historyData,
        "pProdConsByBat",
        period,
        unit,
        pProdConsByBat
      );
      processMeasurePush(
        historyData,
        "pProdSentToGrid",
        period,
        unit,
        pProdSentToGrid
      );
      //conso
      processMeasurePush(
        historyData,
        "pConsoGlobal",
        period,
        Unit.KWH,
        pConsoGlobal
      );
      if (pConsoGlobal.length > 0) {
        setPConsoGlobalValue(pConsoGlobal.map(elt=>elt.measure).reduce((a, b) => a + b));
      }
      processMeasurePush(
        historyData,
        "pConsoFromProd",
        period,
        unit,
        pConsoFromProd,
        (item) => getFormatedDatetime(item, period, labelsConsum)
      );
      processMeasurePush(
        historyData,
        "pConsoFromGrid",
        period,
        unit,
        pConsoFromGrid,
      );
      processMeasurePush(
        historyData,
        "pConsoFromBat",
        period,
        unit,
        pConsoFromBat,
      );

      //forecast 
      
      //prod
      processMeasurePush(
        forecastData,
        "pProdGlobal",
        period,
        Unit.KWH,
        pProdGlobalForecast
      );

      processMeasurePush(
        forecastData,
        "pProdConsByConsumers",
        period,
        unit,
        pProdConsByConsumersForecast,
        (item) => getFormatedDatetime(item, period, labelsProd)
      );
      processMeasurePush(
        forecastData,
        "pProdConsByBat",
        period,
        unit,
        pProdConsByBatForecast
      );
      processMeasurePush(
        forecastData,
        "pProdSentToGrid",
        period,
        unit,
        pProdSentToGridForecast
      );
      //conso
      processMeasurePush(
        forecastData,
        "pConsoGlobal",
        period,
        Unit.KWH,
        pConsoGlobalForecast
      );
      
      processMeasurePush(
        forecastData,
        "pConsoFromProd",
        period,
        unit,
        pConsoFromProdForecast,
        (item) => getFormatedDatetime(item, period, labelsConsum)
      );
      processMeasurePush(
        forecastData,
        "pConsoFromGrid",
        period,
        unit,
        pConsoFromGridForecast,
      );
      processMeasurePush(
        forecastData,
        "pConsoFromBat",
        period,
        unit,
        pConsoFromBatForecast,
      );
    const graphProd = {
      labels: labelsProd,
      datasets: [
        createDataset(
          1,
          "Autoconsommation directe",
          pProdConsByConsumers,
          Sun,
          Sun,
          DataCategory.HISTORY,
          COLORS.PROD,
          COLORS.PROD_OPACITY50
        ),
        createDataset(
          2,
          "Prediction Autoconsommation directe",
          pProdConsByConsumersForecast,
          Sun,
          Sun,
          DataCategory.FORECAST,
          COLORS.PROD,
          COLORS.PROD_OPACITY50
        ),
        createDataset(
          3,
          "Charge batterie",
          pProdConsByBat,
          BatteryUp,
          BatteryUp,
          DataCategory.HISTORY,
          COLORS.BATTERYUP,
          COLORS.BATTERYUP_OPACITY50
        ),
        createDataset(
          4,
          "Prediction Charge batterie",
          pProdConsByBatForecast,
          BatteryUp,
          BatteryUp,
          DataCategory.FORECAST,
          COLORS.BATTERYUP,
          COLORS.BATTERYUP_OPACITY50
        ),
        createDataset(
          5,
          "Surplus réinjecté dans le réseau",
          pProdSentToGrid,
          Pylon,
          BatteryDown,
          DataCategory.HISTORY,
          COLORS.SURPLUS,
          COLORS.SURPLUS_OPACITY50
        ),
        createDataset(
          6,
          "Prédiction Surplus réinjecté dans le réseau",
          pProdSentToGridForecast,
          Pylon,
          BatteryDown,
          DataCategory.FORECAST,
          COLORS.SURPLUS,
          COLORS.SURPLUS_OPACITY50
        ),
      ],
    };

    const graphConso = {
      labels: labelsConsum,
      datasets: [
        createDataset(
          7,
          "Autoconsommation directe",
          pConsoFromProd,
          Sun,
          Sun,
          DataCategory.HISTORY,
          COLORS.PROD,
          COLORS.PROD_OPACITY50
        ),
        createDataset(
          8,
          "Prédiction Autoconsommation directe",
          pConsoFromProdForecast,
          Sun,
          Sun,
          DataCategory.FORECAST,
          COLORS.PROD,
          COLORS.PROD_OPACITY50
        ),
        createDataset(
          9,
          "Décharge batterie",
          pConsoFromBat,
          BatteryDown,
          BatteryDown,
          DataCategory.HISTORY,
          COLORS.BATTERYDOWN,
          COLORS.BATTERYDOWN_OPACITY50
        ),
        createDataset(
          10,
          "Prédiction Décharge batterie",
          pConsoFromBatForecast,
          BatteryDown,
          BatteryDown,
          DataCategory.FORECAST,
          COLORS.BATTERYDOWN,
          COLORS.BATTERYDOWN_OPACITY50
        ),
        createDataset(
          11,
          "Consommation réseau",
          pConsoFromGrid,
          Pylon,
          Pylon,
          DataCategory.HISTORY,
          COLORS.CONSO,
          COLORS.CONSO_OPACITY50
        ),
        createDataset(
          12,
          "Prédiction Consommation réseau",
          pConsoFromGridForecast,
          Pylon,
          Pylon,
          DataCategory.FORECAST,
          COLORS.CONSO,
          COLORS.CONSO_OPACITY50
        ),
      ],
    };

    setDataConso(graphConso);
    setDataProd(graphProd);
  }, [switcherDate, historyData,forecastData, period, unit]);

  const ErrorAlert = ({
    title,
    description,
  }: {
    title: string;
    description: string;
  }) => (
    <EmsAlert status="error" title={title} description={description || ""} />
  );

  const SkeletonContent = ({
    isLoading,
    children,
  }: {
    isLoading: boolean;
    children: ReactNode;
  }) => (
    <Skeleton isLoaded={!isLoading} className="h-full max-h-full min-h-full">
      {children}
    </Skeleton>
  );

  const EnergyPanel = ({
    title,
    value,
    data,
    isLoading,
  }: {
    title: string;
    value: number;
    data: any;
    isLoading: boolean;
  }) => (
    <Panel
      className="max-h-full min-h-full"
      withArrow={false}
      title={
        <div>
          <span className="indicators__list-indicators__graphs-title">
            {title}
          </span>
          <span className="text-[#0488F9] text-xl md:text-2xl font-extrabold mx-2">
            {value && value.toFixed(1)} kWh
          </span>
        </div>
      }
    >
      <Switcher>
        <SkeletonContent isLoading={isLoading}>
          {<LabeledBarChart data={data} displayCols />}
        </SkeletonContent>
      </Switcher>
    </Panel>
  );
  return (
    <Flex
      flex="2"
      py={2}
      px={2}
      flexDirection="column"
      className="overflow-y-auto list"
      gap={2}
    >
      {historyDataIsError || forecastDataIsError ? (
        <ErrorAlert
          title="Erreur lors de la récupération des données"
          description="Error"
        />
      ) : (
        <>
          {[
            {
              title: "Ma production globale d'énergie",
              value: pProdGlobalValue,
              data: dataProd,
              isFetching: historyDataIsFetching || forecastDataIsFetching,
            },
            {
              title: "Ma consommation globale d'énergie",
              value: pConsoGlobalValue,
              data: dataConso,
              isFetching: historyDataIsFetching || forecastDataIsFetching,
            },
          ].map(({ title, value, data, isFetching }, index) => (
            <EnergyPanel
              key={index}
              title={title}
              value={value}
              data={data}
              isLoading={isFetching}
            />
          ))}
        </>
      )}
    </Flex>
  );
};
